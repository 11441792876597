import React from "react";
import '../styles/MainContent.css';
import logoSorbonne from '../assets/sorbonne-logo.png';
import logoCFA from '../assets/CFA.png';

const sectionTitle ={
  fontSize: "2rem",
  // fontWeight: "bold",
  margin: "10px",
}
const sectionSubtitle ={
  padding: "10px 0 10px 0",

  fontSize: "1.7rem",
  // fontWeight: "bold",
  // margin: "10px",
}

function MainContent() {
  return (
    <div className="main-content">
      <div style={sectionTitle}>Work Experience</div>
      <div className="section">
        <div style={sectionSubtitle}>Développeur Full Stack - Alien6</div>
        {/* <Link to="/item/nft-marketplace"> */}
          <div className="item clickable">
            <h3>NFT Marketplace (2022 - 2023)</h3>
            <ul>
              <li>Design and implementation of an NFT marketplace based on blockchain.</li>
              <li>Integration of NFT technology and smart contracts.</li>
            </ul>
          </div>
        {/* </Link> */}
        {/* <Link to="/item/mobile-app"> */}
          <div className="item clickable">
            <h3>Mobile Application (2021 - 2022)</h3>
            <ul>
              <li>Leading a mobile application project for managing IT articles.</li>
              <li>Integration of content customization features based on user preferences.</li>
            </ul>
          </div>
        {/* </Link> */}
      </div>
      <div className="section">
        <div style={sectionSubtitle}>Tuteur Universitaire - Sorbonne Université</div>
        {/* <Link to="/item/nft-marketplace"> */}
          <div className="item clickable">
            <h3>Tuteur du DU RESPE (2019 - 2020)</h3>
            <ul>
              <li>Tuteur du Diplôme Universitaire de Retour aux Etudes Supérieures Des Personnes Exilées à la
              Sorbonne.</li>
              <li>Organisation des séances d'études, assisstance aux dévoirs et aux démarches universitaires de 3 étudiants.</li>
            </ul>
          </div>
      
        {/* </Link> */}
      </div>
      
      <div style={sectionTitle}>Education</div>
<div className="section education">
  <a  target="_blank" rel="noopener noreferrer" style={{ flex: 1, textDecoration: "none", color: "black", display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center" }}>
    <h4 style={{ fontWeight: "bold" }}>LICENCE EN INFORMATIQUE</h4>
    <img src={logoSorbonne} alt="Sorbonne Logo" style={{ width: "200px", marginBottom: "10px" }} />
  </a>
  <div style={{ flex: 0, margin: "0 20px", fontSize: "40px" }}>+</div>
  <a  target="_blank" rel="noopener noreferrer" style={{ flex: 1, textDecoration: "none", color: "black", display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center" }}>
    <h4 style={{ fontWeight: "bold" }}>BAC+5 - ARCHITECTE TECHNIQUE EN INFORMATIQUE ET RÉSEAUX</h4>
    <img src={logoCFA} alt="CFA Logo" style={{ width: "150px", marginBottom: "10px" }} />
  </a>
</div>

<div style={sectionTitle}>Languages</div>
<div className="section languages-section">
        <ul>
          <li>English</li>
          <li>Ukrainian</li>
          <li>French</li>
          <li>Russian</li>
        </ul>
      </div>
      <div style={sectionTitle}>Projects</div>

<div className="section">
<div className="item clickable">
    <h4>AI Clustering for Anime Recommendations: Using MyAnimeList API</h4>
    <ul>
      <li>Implemented an AI clustering algorithm for recommending anime using the MyAnimeList API.</li>
      <li>Developed an interactive visual interface for enhanced user engagement and experience.</li>
    </ul>
  </div>
  <div className="item clickable">
    <h4>Web Application: Back-office web (Angular + Python DRF)</h4>
    <ul>
      <li>Developed a robust web back-office using Angular and Python Django Rest Framework.</li>
      <li>Designed to optimize internal management processes and improve operational efficiency.</li>
    </ul>
  </div>

  <div className="item clickable">
    <h4>Plagiarism Detection Algorithm: Source code string comparison</h4>
    <ul>
      <li>Designed a sophisticated algorithm to detect plagiarism in source codes.</li>
      <li>Uses advanced techniques to compare code segments and identify similarities.</li>
    </ul>
  </div>

  <div className="item clickable">
    <h4>Code Reader: C Linter written in Java</h4>
    <ul>
      <li>Created a linting tool for the C language, developed in Java.</li>
      <li>Allows efficient analysis and improvement of C code by identifying errors and style issues.</li>
    </ul>
  </div>

  <div className="item clickable">
    <h4>Chrome Extension: Right-click shortcut to send files on Discord</h4>
    <ul>
      <li>Developed a Chrome extension to simplify file sending on Discord.</li>
      <li>Integrates a contextual menu for quick file sending via right-click.</li>
    </ul>
  </div>

  <div className="item clickable">
    <h4>Pac-Man: Custom version of Pac-Man developed in Java (JavaFX)</h4>
    <ul>
      <li>Created a customized version of the classic Pac-Man game using Java and JavaFX.</li>
      <li>Additional features and improved graphics for a unique gaming experience.</li>
    </ul>
  </div>


</div>

    </div>
  );
}

export default MainContent;
