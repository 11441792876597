import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./styles/App.css";
import MainContent from "./components/MainContent";
import ItemDetail from "./components/ItemDetail";
import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import { Parallax } from "react-parallax";
import bg from "./assets/bg.jpg"; // Import your background image

function App() {
  return (
    <Router>
      <div className="App">
        <div className="main-content-area">
          <Header />
          <Parallax Parallax bgImage={bg} strength={700}>
            <div className="resume-content">
              <Routes>
                <Route path="/" exact element={<MainContent />} />
                <Route path="/item/:id" element={<ItemDetail />} />
              </Routes>
            </div>
          </Parallax>
        </div>
        <Sidebar />
      </div>
    </Router>
  );
}

export default App;
